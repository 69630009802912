import { Fragment } from 'react';
import UILIB from 'components';

export default function ConsumablesLookupSerialNumberDetails({ entityLookup }) {

  return <Fragment>
      <UILIB.Paper className='width-100'>
        <div className='flex-container evenly wrap'>
          <div className='flex-item'><b>Customer</b>&nbsp;{entityLookup.customerName}</div>
          <div className='flex-item'><b>Device</b>&nbsp;{`${entityLookup.serialNumber} - ${entityLookup.description}`}</div>
          <div className='flex-item'><b>Location</b>&nbsp;{entityLookup.location}</div>
        </div>     
      </UILIB.Paper>
  </Fragment>
}