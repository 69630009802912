import { useState, useEffect, Fragment } from 'react';
import { setOrder, calcHeaderColour, spawnHeaderType } from '../functions';

export default function ColumnHeader({ header, settings, render, setRender, moduleRender, setModuleRender, checked, setChecked, selectAll, tableData, selectQuery }) 
{
  const [renderLocal, setRenderLocal] = useState(false);

  const changeOption = (col, filtering) => {
    if(!filtering) return false;

    let click = (!isNaN(settings.current.paging.orderClick)) 
      ? (settings.current.paging.orderClick === 2) ? 0 : (settings.current.paging.orderClick + 1) 
      : 1;
    
    if(String(col.value).toLocaleLowerCase() !== String(settings.current.paging.orderBy).toLocaleLowerCase()) click = 1; 
    if(String(col.value) !== String(settings.current.paging.orderBy)) click = 1;

    const orderBy = (Number(click) === 0) 
      ? settings.current.paging.fallback.orderBy 
      : col.value;

    if(String(orderBy) === String(settings.current.paging.fallback.orderBy)) click = 1;

    let orderDir = (Number(click) === 0) 
      ? settings.current.paging.fallback.orderDir 
      : (String(settings.current.paging.orderDir).toLocaleUpperCase() === 'ASC') ? 'DESC' : 'ASC';

    if(String(col.value) !== String(settings.current.paging.orderBy)) orderDir = 'ASC';

    const hasReset = ((settings.current.paging.fallback.orderBy !== settings.current.paging.orderBy) && 
      (settings.current.paging.fallback.orderBy === orderBy));

    const resetClick = (String(settings.current.paging.fallback.orderDir).toLocaleUpperCase() === 'ASC') ? 2 : 1;

    const settingsBase = { ...settings.current, paging: { 
      ...settings.current.paging, 
      orderBy: (hasReset) ? settings.current.paging.fallback.orderBy : orderBy, 
      orderDir: (hasReset) ? settings.current.paging.fallback.orderDir : orderDir, 
      orderClick: (hasReset) ? resetClick: click } };

    settings.current = settingsBase;
    
    setOrder(settingsBase);
    setRenderLocal(!renderLocal);
  }

  const changeHeaderDirection = (direction, orderClick, style, sortable, col) => {
    if (settings.current.sortable && col.type) style.cursor = 'pointer';
    const active = (settings.current.paging.orderBy === col.value && Number(settings.current.paging.orderClick) !== 0);
    const asc = (direction === 'ASC' && orderClick !== 0 && (sortable && col.type) && active) ? ' hide ' : '';
    const desc = (direction === 'DESC' && orderClick !== 0 && (sortable && col.type) && active) ? ' hide ' : '';
    return  <div className="flex-container center column around nowrap pad-r2">
              <div className="flex-item sortLabel"><div className={`up-arrow ${asc}`}></div></div>
              <div className="flex-item sortLabel"><div className={`down-arrow ${desc}`}></div></div>
            </div>
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      setRender(!render);
    }, 100);
    return () => clearTimeout(timer);
  }, [renderLocal])

  return header.map((col, index) => {
    const minWidth = (col?.minWidth && !col?.width) ? { minWidth: col?.minWidth } : {};
    const maxWidth = (col?.maxWidth && !col?.width) ? { maxWidth: col?.maxWidth } : {};
    const width = (isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth)) ? { width: 'auto' } : {};
    const widthFinal = ((isNaN(minWidth?.minWidth) && isNaN(maxWidth?.maxWidth) && isNaN(width?.width))) ? { width: 'auto' } : {};
    const align = (col?.align) ? col?.align : '';
    const style = { textAlign: align, ...minWidth, ...maxWidth, ...width, ...widthFinal };
    const filtering = (col?.filtering);
    col.style = style;
    return <th key={"th_" + index} className={`${calcHeaderColour(settings.current.paging, col, 2, settings.current)} mainTable headerBorders`} style={style} onClick={() => changeOption(col, filtering)}>
      <Fragment>
        <div className="flex-container center row nowrap width-100 height-100 row-hover">
          {spawnHeaderType(checked, setChecked, selectAll, col, tableData, moduleRender, setModuleRender, selectQuery)}
          {filtering && <div className="flex-item end">{changeHeaderDirection(settings.current.paging.orderDir, settings.current.paging.orderClick, style, settings.current.sortable, col)}</div>}
        </div>
      </Fragment>
    </th>
  })
}
