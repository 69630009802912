import Axios from 'axios';
import UILIB from 'components';
import { Fragment, useRef, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import ClickAway from 'components/ClickAway';


export default function AutoCompleteMulti({ 
    className = '',
    textInputClassName = '',
    buttonClassName = '',
    buttonText,
    style = {}, 
    name = '',
    query = null, 
    errored = { messageText: "", option: 0 },
    loading = { get: null, set: null },
    onClick = async () => { }, 
    onClearDown = async () => { }, 
    enableButton = true,
    placeholder = "Type to Start Searching", 
    blank = '', 
    defaultValue = '', 
    minLength = 3,
    disabled = false }) 
{

    const timer = useRef(null);
    const cancelToken = useRef(Axios.CancelToken);
    const source = useRef(null);
    const input = useRef(null);

    const [firstLoad, setFirstLoad] = useState(true);
    const [searchText, setsearchText] = useState(defaultValue);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [show, setShow] = useState(false);
    const [searching, setSearching] = useState(false);


    const search = async (searchText) => {

      if(!searchText || !searchText.length) return;

      if (source.current && typeof source.current.cancel === 'function') {
        await Promise.resolve(source.current.cancel('cancelled'));
      }

      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(async () => {
        try {
          setShow(true);
          setSearching(true);

          const newSource = cancelToken.current.source();
          source.current = newSource;

          if (searchText && searchText.length < minLength) {
            setData([]);
          } else if (typeof (query) === 'function') {
            if(typeof(loading) === 'object' && loading.set) loading.set(true);
            const results = await query(searchText, { cancelToken: newSource.token }, ...arguments);
            setData(results.map(x => { return { ...x, value: x.value, label: x.label } }));
          }

          setSearching(false);

        } catch (err) {
          if(typeof(loading) === 'object' && loading.set) loading.set(false);
          setSearching(false);
        }
      }, 200)
    }

    const updateSelected = (val) => {
      if (selected.some(x => Number(x) === Number(val))) {
        setSelected([...selected.filter(x => Number(x) !== Number(val))]);
      } else {
        setSelected([...selected, val]);
      }
    }

    const onChangeValue = async (ev) => {
      const rawIds = data.map(x => x.value);
      setSelected(selected.filter(x => rawIds.includes(x)));
      setFirstLoad(false);
      setsearchText((ev.target.value) ? ev.target.value : '');
      await search(ev.target.value);
    }

    const actionClearData = async () => {
      setShow(true);
      if(searchText === '') setSelected([]);
      await onClearDown();
    }

    const actionClickAway = async () => {
      setShow(false);
    }

    const actionResponseData = async (ev) => {
      const filtered = data.filter(x => selected.includes(Number(x.value)));
      await onClick(filtered, ...arguments, ev);
    }

    return <Fragment>
      <div className={`flex-item align-center end flex-grow-1 height-100 ${className}`}>
        <div className={`flex-container row width-100 nowrap`}>
          <div className={`autocomplete flex-container column overflow-hidden height-100 width-100`}>
            <TextInput 
              name={name}
              outerclassNname='autocomplete height-100'
              className={`textInput autocomplete searchBox noBorder height-25-px ${textInputClassName}`}
              style={style}
              ref={input} 
              value={(firstLoad && (searchText && !searchText.length)) ? defaultValue : searchText} 
              onChange={async (ev) => await onChangeValue(ev)} 
              onClick={async () => await actionClearData()} 
              placeholder={placeholder}
              errored={errored}
              disabled={disabled} />
            {Boolean(searchText && searchText.length >= 3 && show) && 
              <ClickAway onClickAway={() => actionClickAway(false)}>
                <div className="container flex-item row" style={{ width: (input.current) ? Math.round(input.current.getBoundingClientRect().width) : 'auto', align: "left", marginTop: 0 }}>
                  {Boolean(searching && !enableButton) && <div className="flex-item row start center align-center width-100 height-22-px"><UILIB.Loading colourClass={'colour background grey'} type={2}/></div>}
                  <div className={"multiSelect fakeSelect fakeSelectDropDown flex-container row flex-grow-1"}>
                    {Boolean(data && data.length) && data.map((opt, index) => {
                      const checked = selected.some(x => Number(x) === Number(opt.value));
                      return (
                        <div className="flex-item start clickable width-100 border-bottom-1" key={index}>
                          <input 
                            className="flex-item align-start" 
                            type="checkbox" 
                            checked={checked} 
                            onChange={() => updateSelected(opt.value)} 
                          /> 
                          {opt.label}
                        </div>
                      ) 
                    })}
                  </div>
                  {Boolean(!data && data.length && !searching) && <div>{blank}</div>}
                </div>
              </ClickAway>
            }
          </div>
          {Boolean(enableButton) && 
            <UILIB.Button 
              loading={searching} 
              disabled={(disabled || searching || !selected.length)} 
              value={buttonText || "Apply"} 
              className={`button autoComplete small min-width-80 height-25-px ${buttonClassName}`} 
              onClick={async (ev) => await actionResponseData(ev)} 
            />
          }
        </div>
      </div>
    </Fragment>
}