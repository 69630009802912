import UILIB from "components";
import { memo } from 'react';

const DataRowNested = memo(({ 
  localRender,
  headerData, 
  entityData, 
  className = {}, 
  tableSettings = {}
}) => 
{ 
  const defaultSettings = {
    expanding: false,
    filtering: false,
    sortable: false,
    caching: false,
    filterEnabled: false, 
    refreshEnabled: false,
    paginationEnabled: false,
    showRecords: false,
    exportEnabled: false,
    expandEnabled: false,
    deleteEnabled: false,
    headerEnabled: false,
    zebraRows: {
      enabled: true,
      primaryColour: 'colour background-2 green',
      secondaryColour: 'colour background-2 green',
    },
    ...tableSettings
  }

  const defaultStyle = {
    tableAlign: 'start',
    tableHeaderContainer: null,
    tableItemStyle: 'width-100',
    tableContainerStyle: 'pad-10',
    tableClassName: 'mar-b0',
    tableName: 'defaultExpandTable',
    includeTable: true,
    ...className
  }

  return <div className={`flex-container row ${defaultStyle.tableAlign} width-100 ${defaultStyle.tableContainerStyle}`}>
      <div className={`flex-item`}>{defaultStyle.tableHeaderContainer}</div>
      <div className={`flex-item ${defaultStyle.tableItemStyle}`}>
      {defaultStyle.includeTable && (
        <UILIB.TableNew
          name={defaultStyle.tableName}
          className={`nestedTable ${defaultStyle.tableClassName}`}
          optionsContainerclassName='mar-0'
          overflowX='auto'
          overflowY='auto'
          header={headerData}
          localQuery={() => (entityData.length) ? entityData : []} 
          localRender={[localRender]}
          defaultSettings={defaultSettings} />
      )}
      </div>
    </div>
})
export default DataRowNested