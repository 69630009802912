import { useState } from "react";

export default function useOpenController(initialState, primaryKey) {

  const [isOpen, setOpenState] = useState({ [primaryKey]: initialState });
  
  const toggle = () => {
    setOpenState((state) => { return { [primaryKey]: !state[primaryKey] } });
  }

  return { isOpen, setOpenState, toggle };
}