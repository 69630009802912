import { useState, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'classes/axios';
import UILIB from 'components';
import { createButton } from 'classes/controls/formControls';


export default function NoteCRMSync({ deal }) 
{  

  const [saving, setSaving] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [selected, setSelected] = useState(deal.dealNote
    .filter(x => Number(x.noteSourceId) === 3)
    .reduce((acc, obj) => ({ ...acc, [obj.notesID]: false }), {})
  );

  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionSaveForm = async () => {
    setSaving(true);
    try { 
      const objectKeys = Object.keys(selected).map(x => Number(x)).filter(x => selected[x]);
      const filtered = deal.dealNote.filter(f => objectKeys.includes(Number(f.notesID)));
      const result = await Axios.patch(`/entities/workflow/deals/actionSyncCRMNotes/${deal.dealID}`, filtered); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) toast.error(invalidConfig);
        if(result.status === 404) toast.error(notFoundError);
        if(result.status === 409) toast.error(versionError);
      } else {
        toast.info(`Notes successfully synced to CRM`);
      }
    } catch (err) {
      console.log(err);
      toast.error(exceptionError);
    } finally {
      setSaving(false);
    }
  }

  const actionClearNotes = async () => {
    setClearing(true);
    try { 
      const result = await Axios.patch(`/entities/workflow/deals/actionSyncCRMNotes/${deal.dealID}`, []); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) toast.error(invalidConfig);
        if(result.status === 404) toast.error(notFoundError);
        if(result.status === 409) toast.error(versionError);
      } else {
        toast.info(`Notes successfully cleared from CRM`);
      }
    } catch (err) {
      console.log(err);
      toast.error(exceptionError);
    } finally {
      setClearing(false);
    }
  }

  const actionHandleClicked = (note) => {
    setSelected({ ...selected, [note.notesID]: !selected[note.notesID]});
  };

  return <Fragment>
    <div className="flex-container row width-100 wrap">
      <div className="flex-container row nowrap width-100 mar-t15 colour background-6 lightGrey mar-b3">
        <div className="flex-item start align-center flex-grow-1 font-weight-bold text-12">Click to Sync the notes in CRM for this oppotunity</div>
        {createButton('', 'syncNotes', 'Sync', (saving || clearing || !Object.keys(selected).map(x => Number(x)).some(x => selected[x])), saving, async () => await actionSaveForm(), null, 'sopHub sopDrawer-sync-right mar-r10', null, null, null)}
      </div>
      <div className="flex-container row end nowrap width-100 colour background-6 lightGrey">
        <div className="flex-item start align-center flex-grow-1 font-weight-bold text-12">Click to Clear the notes in CRM for this oppotunity</div>
        {createButton('', 'clearNotes', 'Clear', (saving || clearing), clearing, async () => await actionClearNotes(), null, 'sopHub sopDrawer-sync-right mar-r10', null, null, 'red')}
      </div>
      <div className={`flex-container row padding-4 width-100`}>
        {Boolean(!deal.dealNote || !deal.dealNote.length) && <div className="text-14 font-weight-bold">No notes available for this deal.</div>}
        {Boolean(deal.dealNote && deal.dealNote.length) && <div className="width-100">
          {deal.dealNote
            .filter(x => Number(x.noteSourceId) === 3)
            .map((x, i) => {
              return (
                <div key={i} className={`flex-container row nowrap width-100 mar-t10 mar-b10`}>
                  <div className={`flex-item`}>
                    <UILIB.Checkbox name="datesApproved" checked={selected[x.id]} onChange={() => actionHandleClicked(x)} />
                  </div>
                  <div className={`flex-container row width-100`}>
                    <div className={`flex-container row nowrap start width-100 colour background lightGrey padding-2`}>
                      <div className={`flex-item start flex-grow-1 mar-r20 text-13`}>{x.createdByTxt}</div>
                      <div className={`flex-item end mar-r20 text-13`}>{formatDateTime(x.updatedAt, "DD-MM-YYYY HH:mm:ss")}</div>
                    </div>
                    <div className={`flex-container row nowrap start flex-grow-1 width-100 height-100 padding-2 colour background-4 lightGrey`}>
                      <div className={`flex-item text-12 pad-8 consumablesHub tonerRobotNotes-text-content`}>{x.theNotes}</div>
                    </div>
                  </div>
                </div> 
              )
            }
          )}
        </div>}
      </div>
    </div>
  </Fragment>
}
