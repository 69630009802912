import UILIB from 'components';
import { setDrawer } from 'store/actions';
import ViewNotesDrawer from './drawer/viewNotesDrawer';
import Axios from "classes/axios";
const moment = require('moment');
const todayMinusFive = moment().subtract(5, 'days').utc().format('YYYY-MM-DD');

export function getYesNo() {
    return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }]
}

const normaliseNoteData = (voNoteData, noteData, source) => {
  if ((!noteData || !Array.isArray(noteData)) && (!voNoteData || !Array.isArray(voNoteData))) {
    return [];
  }

  if(source === "VO") {
    return voNoteData.map(note => {
      const isException = noteData.some(n => n.vONoteId === note.Id && n.NoteSource === 'Exception');
      return {
        id: note.Id,
        equipmentId: note.ParentId,              
        text: note.Text,
        loggedBy: note.LoggedBy,
        modifiedBy: note.ModifiedBy,
        loggedDate: note.LoggedDate,
        modifiedDate: note.ModifiedDate,
        noteSource: isException ? 'Exception' : source
      }
    });
  }
  else if(source === "MIF") {
    return noteData.map(note => ({
      id: note.id,
      equipmentId: note.id,             
      text: note.note,
      loggedBy: note.createdByTxt,
      modifiedBy: note.updatedByTxt,
      loggedDate: note.createdAt,
      modifiedDate: note.updatedAt,
      noteSource: note.NoteSource === "MIF" ? "RMB" : note.NoteSource
    }));
  }
  else {
    return {};
  }
}

export async function actionOpenNotesDrawer(type, entity, dispatch, enabled, sync, isException) {
  let noteData;

  if(entity.voNotes && entity.voNotes !== 0 && entity.voNotes.length > 0) {
    noteData = normaliseNoteData(entity.voNotes, entity.notes, "VO")
  }
  else if(entity.notes && entity.notes !== 0 && entity.notes.length > 0) {
    noteData = normaliseNoteData(entity.voNotes, entity.notes, "MIF")
  }
  

  if(!enabled) return false;

  const selector = (type, data, serialNumber) => {

    if(type === 'ViewNotesDrawer') return (
      <ViewNotesDrawer 
        entity={data}
        serialNumber={serialNumber}
        onClose={() => dispatch(setDrawer({ open: false, content: null }))} 
        sync={sync} 
        isException={isException} 
      /> 
    );
  };
  dispatch(setDrawer({ 
    open: true, 
    width: "600px",
    content: selector(type, noteData, entity.SerialNumber)
  }))
}

export function actionOpenExceptionsDrawer(type, entity, dispatch, enabled, sync) {
    if(!enabled) return false;
    const selector = (type, data) => {
      if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    };
    dispatch(setDrawer({ 
      open: true, 
      width: "600px",
      content: selector(type, entity)
    }))
}

export function tableContainer(onClick, icon, toolTip, colour, hover, hasVoNotes) {
    return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function setFilterVars(context, filter) {
    if(filter.button === 'accountNumber') {
        return ` (accountNo like ${filter.accountNumber}) `;
    }
    if(filter.button === 'totalMif') {
        return ``;
    }
    if(filter.button === 'totalCustomersImpacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'ricohRemoteImpacted') {
      return `mifException eq 0 and (lastRemoteDate isn null and lastRemoteDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'konicaCsrcImpacted') {
      return `mifException eq 0 and (lastKonicaDate isn null and lastKonicaDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'canonEMainImpacted') {
      return `mifException eq 0 and (lastEmDate isn null and lastEmDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'monitoredMifConnectivityRate') {
      return `mifException eq 0 and (lastScannedAnyDate gt ${todayMinusFive}) `;
    }
    if(filter.button === 'totalDevicesImpacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `;
    }
    if(filter.button === 'kpaxImpacted') {
      return `mifException eq 0 and (lastKpaxDate isn null and lastKpaxDate lte ${todayMinusFive} and lastSiteAuditDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'siteAuditImpacted') {
      return `mifException eq 0 and (lastSiteAuditDate isn null and lastSiteAuditDate lte ${todayMinusFive} and lastKpaxDate is null and LastHpDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'hpWjaImpacted') {
      return `mifException eq 0 and (LastHpDate isn null and LastHpDate lte ${todayMinusFive} and lastKpaxDate is null and lastSiteAuditDate is null and lastRemoteDate is null and lastKonicaDate is null and lastEmDate is null and lastLeemicDate is null and lastPipsDate is null and lastOtherDate is null and lastCslDate is null and lastKdDate is null) `;
    }
    if(filter.button === 'groupCode') {
      return ` (groupCode eq ${filter.groupCode}) `;
    }
    if(context === 'impacted') {
      return `mifException eq 0 and (lastScannedAnyDate lte ${todayMinusFive} or lastScannedAnyDate is null) `; 
    }
    else if(context === 'neverCommunicated') {
      return `(neverCommunicated ne 0 or neverCommunicated is null) `;
    }
  }

  export function rowSelected(event, row, setSelectedRows, page) {
    const isChecked = event.target.checked;
    const rowId = row['id'];
    const equipmentId = row['equipmentId'];
    const reason = row['reason'];
    const hasVoNotes = Boolean(row.voNotes && row.voNotes.length);
    const isException = page === 'exceptionsList' ? true : row.mifException;
    const exceptionId = row['exceptionId'];

    setSelectedRows(prevState => ({
      ...prevState,
      [rowId]: {
        isChecked,
        hasVoNotes,
        isException,
        equipmentId,
        reason,
        exceptionId
      }
    }));
  };

  export function selectAllRows(event, entityData, setSelectedRows) {
    const isChecked = event.target.checked;

    setSelectedRows(prevState => {
      const newSelectedRows = {};

      entityData.current.result.forEach(row => {
        const hasVoNotes = Boolean(row.voNotes && row.voNotes.length);

        newSelectedRows[row['id']] = {
          isChecked,
          hasVoNotes: prevState[row['id']]?.hasVoNotes || hasVoNotes,
          isException: prevState[row['id']]?.isException ?? row.mifException,
          equipmentId: prevState[row['id']]?.equipmentId ?? row.equipmentId,
          reason: prevState[row['id']]?.reason ?? row.reason,
          exceptionId: prevState[row['exceptionId']]?.exceptionId ?? row.exceptionId
        };
      });

      return newSelectedRows;
    });
  }

  export function checkRow (id, entityData, selectedRows, voOnly) {
    const item = entityData.current.result.find(item => String(item['id']) === String(id));

    if(voOnly) {
      return Object.values(selectedRows).some(selectedRow => selectedRow.isChecked && selectedRow.hasVoNotes);
    }

    return item?.notes?.some(note => note.NoteSource === 'Exception') || 
      Object.values(selectedRows).some(selectedRow => selectedRow.isChecked && selectedRow.hasVoNotes);
  };

  export function getSelectedRowIds(selectedRows) {
    return Object.entries(selectedRows)
      .filter(([id, row]) => row.isChecked)
      .map(([id, row]) => ({
          id: Number(id),
          equipmentId: Number(row.equipmentId),
          noteText: row.reason,
          hasVoId: row.hasVoNotes
      }));
  }

  export function getSelectedNoteIds(selectedEntities) {
    const selectedNoteIds = [...new Set(selectedEntities
      .filter(entity => entity.voNotes && entity.voNotes.length > 0)
      .flatMap(entity => {
        const noteVONoteIds = new Set(entity.notes.map(note => note.vONoteId));
        return entity.voNotes.filter(voNote => noteVONoteIds.has(voNote.Id));
      })
      .map(voNote => voNote.Id))];

    return selectedNoteIds;
  }

  export function hasNotesOfType(notes, type, isSoftDelete) {
    return Boolean(
      notes &&
      notes.length > 0 &&
      notes.some(note => isSoftDelete ? note.isDeleted : !note.isDeleted) &&
      notes.some(note => note.NoteSource === type)
    );
  }

  export function getNoteColoursAndLabels(hasNotes, hasVoNotes, hasMIFNotes, hasExceptionNotes) {
    let noteColour;
    let noteLabel;

    if (!hasNotes) {
      noteColour = 'colour lightGrey';
      noteLabel = 'No Notes';
    } else if (hasExceptionNotes) {
      noteColour = 'colour background-6 red';
      if (hasVoNotes && hasMIFNotes) {
        noteLabel = 'Exception + VO & RMB Notes';
      } else if (hasVoNotes) {
        noteLabel = 'Exception + VO Notes';
      } else if (hasMIFNotes) {
        noteLabel = 'Exception + RMB Notes';
      } else {
        noteLabel = 'Exception Notes';
      }
    } 
    else if (hasVoNotes || hasMIFNotes) {
      noteColour = 'colour background-6 green';
      if (hasVoNotes && hasMIFNotes) {
        noteLabel = 'VO & RMB Notes';
      } else if (hasVoNotes) {
        noteLabel = 'VO Notes';
      } else {
        noteLabel = 'RMB Notes';
      }
    } 

    return { noteColour, noteLabel };
  }

  export async function actionUpdateCollectionMethods(selectedNoteIds, collectionMethod) {
    if (!selectedNoteIds.length) return;

    const equipmentIds = selectedNoteIds.map(id => id.equipmentId);

    try {
      const responses = await Promise.all(
        equipmentIds.map(equipmentId =>
          Axios.patch(`/entities/workflow/mif/actionUpdateCollectionMethod/${equipmentId}/${collectionMethod}`)
        )
      );

      return responses;
    } catch (error) {
      console.error('Error in actionUpdateCollectionMethods:', error);
    }
  }