import Modules from '../Modules';
import { spawnContainerType } from '../functions';
import { memo } from 'react';

const DataRow = memo(({ header, row, rowIndex, expanding, checked, setChecked, selectQuery, onClick, settings }) => 
{ 

  const rowColour = (settings.current.zebraRows.enabled) 
    ? (rowIndex % 2 === 0) ? settings.current.zebraRows.primaryColour : settings.current.zebraRows.secondaryColour
    : ''

  return <tr className={`row-hover ${rowColour}`} key={"th_" + rowIndex}>
          {header.map((headerColumn, columnIndex) => {
            const defaults = { minWidth: 50, maxWidth: 300, padding: 0, align: 'left' };
            const columnData = row[headerColumn.value] || '';
            const { label, type, value, filtering, ...style } = headerColumn;
            const headerStyle = (columnData.tdStyle) ? columnData.tdStyle : '';
            const columnClass = (columnData.class) ? columnData.class : '';
            const clickable = (headerColumn?.clickable) ? onClick : function () { }
            
            const columnDataFinal = (columnData.hasOwnProperty('value') ? spawnContainerType(headerColumn?.type, headerColumn?.behaviorType, columnData, checked, setChecked, selectQuery) : columnData);
            return <td onClick={clickable} key={"td_" + rowIndex + "_" + columnIndex} style={{ ...defaults, ...style, ...headerStyle }} className="mainTable cellBorders">
              {<Modules.DataColumn expanding={expanding} className={columnClass} style={style}>{columnDataFinal}</Modules.DataColumn>}
            </td>
          })}
        </tr>
})
export default DataRow