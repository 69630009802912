import UILIB from 'components';
import RowDealsView from './rowDealsView';
import RowCustomersView from './rowCustomersView';
import BlockOpenQuotes from './blockOpenQuotes';
import BlockActiveDeals from './blockActiveDeals';
import BlockInvoicedThisMonth from './blockInvoicedThisMonth';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

export default function AccountManagerView() {
    const history = useHistory();
    const account = useSelector(state => state.account)
    const isManager = account.managerUsers && !!account.managerUsers.length;

    return <div className="flex-container row mar-t5">
        <div className="col-xs-12 col-sm-6 mar-b5">
            <UILIB.Button onClick={() => { window.open('https://portfolio.agilico.co.uk', '_blank') }} className="primary mar-r10" value="Sales Portfolio" />
            <UILIB.Button className="primary mar-r10" value="Refurb kit for sale" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/sales/refurbkit/", "_blank") }} />
            <UILIB.Button className="primary mar-r10" value="Customer Inventory Report" onClick={() => history.push({ pathname: '/salesHub/customerInventoryReport' })} />
        </div>
        <div className="col-xs-12 col-sm-6 mar-b5 text-right">
            {isManager && <UILIB.Button className="mar-l10" value="Team View" onClick={() => history.push('/saleshub/salesmanagerview')} />}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockOpenQuotes/>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockActiveDeals />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockInvoicedThisMonth />
        </div>
        <div className="col-xs-12">
            <RowDealsView />
            <RowCustomersView />
        </div>
    </div >
}
