import { useCallback, useState } from "react";

export default function useToggle(initialState, setState = undefined) {

  const [isOpen, setOpenState] = useState(initialState);
  
  const toggle = useCallback(() => {
    setOpenState((state) => !state);
    if(setState) setState((state) => !state);
  }, [setOpenState]);

  return { isOpen, toggle };
}